.Caption {
  color: white;
  font-family: "Inter-Bold";
  width: 70%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  div {
    h1 {
      font-family: "Inter-Bold";
      text-align: left;
      font-size: clamp(3rem, 12vw, 7rem);
    }
  }
  @media only screen and (max-width: 1370px) {
    width: none;
    margin-left: 10vw;
  }
}
