@import "../../Shared/FadeIn.scss";

.Title {
  color:white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1,
  h2 {
    // color: #eee;
    margin: 0;
    text-align: center;
  }

  h1 {
    // fixes bug on safari
    transform: translateZ(0px);
    
    letter-spacing: 2px;
    .first,
    .last {
      display: inline-block;
      transform: rotateX(90deg) rotateY(0deg);
      transform-style: preserve-3d;
      transform-origin: center 60%;
      animation: 0.2s linear dropIn forwards;
    }
    .first {
      animation-delay: 1.1s;
    }
    .last {
      animation-delay: 1.4s;
    }
    font-size: clamp(3rem, 14vw, 4.5rem);
    text-transform: uppercase;
    line-height: 1.2;
    font-family: "Inter-Bold";
    margin: 1rem 1rem;
  }
  h2 {
    opacity: 0;
    animation: 0.3s linear fadeIn 1.8s forwards;
    font-size: clamp(1rem, 4vw, 2rem);
    font-family: "Inter-SemiBold";
    margin: 0 0.5rem;
  }
}
@keyframes dropIn {
  0% {
    transform: rotateX(90deg) ;
    // transform: scale(0);
  }
  100% {
    transform: rotateX(0deg);
    // transform: scale(1);
  }
}
