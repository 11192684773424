.Card:hover .content {
  transform: translateY(-50%);
}
.Card:hover .top-left,
.Card:hover .bottom-right {
  width: 100%;
  height: calc(100% + 0.2rem);
  padding: 0.1rem;
}

.Card {
  margin: 1rem;

  position: relative;
  .top-left,
  .bottom-right {
    transition: 1s ease height, 1s ease width;
    border-radius: 15px;
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    background: linear-gradient(to top left, #ff0088 0%, #9000ff 100%);
  }

  .top-left {
    bottom: -0.2rem;
    right: -0.2rem;
  }
  .bottom-right {
    top: -0.2rem;
    left: -0.2rem;
  }
  .content-wrapper {
    overflow: hidden;
    height: 100%;
    display: flex;
    position: relative;
    background: #1d1d1d;
    border-radius: 15px;
    .footer {
      z-index: 1;
      text-align: center;
      width: 100%;
      padding: 0.7rem 0;
      background: linear-gradient(18deg, rgba(255,252,0,1) 0%, rgba(255,192,30,1) 100%);
      font-size: 1rem;
      color:black;
      // background: yellow;
      position: absolute;
      top:0;
      a {
        font-weight: bold;
        color:inherit;
        // color:yellow;
      }
    }
   }
  .content {
    transition: 0.6s ease transform;
    position: relative;
    height: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    background: #1d1d1d;
    height: 21.5rem;
    max-width: 400px;
    text-align: left;

    .links {
      width: 3rem;
      height: 3rem;
      // background: red;
      width: 100%;
      position: absolute;
      bottom: -32%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        padding: 0.7rem 0;
        width: 50%;
        border-radius: 5px;
        text-align: center;
        background: linear-gradient(to top left, #ff0088 0%, #9000ff 100%);
        // background-size: 500% auto;
        background-position: top left;
        // background: #333333;s
        font-family: "Inter-Bold";
        font-size: 1rem;
        // width: 100%;
        text-decoration: none;
        color: white;
        // margin-left: 1rem;
        margin-bottom: 1rem;
        transition: 0.3s ease;
        &:hover {
          transform:scale(1.05);
        }
      }
    }


  }

  img {
    image-rendering: crisp-edges;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 50%;
    width: 100%;
  }
  h1,
  p {
    margin: 1rem;
    color: white;
  }
  h1 {
    font-family: "Inter-Bold";
    font-size: 1rem;
    margin-bottom: 0rem;
  }
  p {
    font-size: 0.9rem;
    margin-top: 0.6rem;
    opacity: 0.7;
  }
  ul {
    flex-wrap: wrap;
    display: flex;
    list-style: none;
    margin-left: 1rem;
  }
}
