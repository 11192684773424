@import "../../Shared/FadeIn.scss";

.Navbar.hide {
  & > * {
    opacity: 0 !important;
  }
}

.Navbar {
  & > * {
    transition: 0.5s ease opacity;
  }
  z-index: 1000;
  opacity: 0;
  animation: 0.3s linear fadeIn 1.8s forwards;
  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  .darken {
    z-index: -1;
    transition: 2s ease opacity;
    opacity: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    position: absolute;
    height: 100%;
    width: 100%;
  }
  h1 {
    font-family: "Inter-SemiBold";
    font-size: 1.5rem;
    cursor: pointer;
  }
  .nav-item {
    pointer-events: all;
    user-select: none;
    cursor: pointer;
  }
}
