.Cards {
  // max-width: 90%;
  // margin: auto;

  //width must be 100-margin*2
//   margin: 0 5%;
//   width: 90%;
  display: flex;
  justify-content: center;
  .grid {
    // margin: 2rem;
    // max-width:1500px;
    width: 100%;
    display: grid;
    // grid-auto-flow: row;
    justify-content: center;
    // grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(280px, 300px));
    @media only screen and (max-width: 400px) {
      grid-template-columns: 1fr;
    }
  }
}
