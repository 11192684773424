.paint-hover {
    display: inline-block;
    color: #eb6242;
    overflow: hidden;
    // background: linear-gradient(to right, #ff0088, #ff0088 50%, white 50%);
    background: linear-gradient(to right, #FF0088 0%, #9000FF 50%, white 50%);

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 500ms ease;
  }
  .paint-hover:hover {
    background-position: 0 100%;
  }