@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url(./assets/Inter/Inter-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: local("Inter-SemiBold"),
    url(./assets/Inter/Inter-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Light";
  src: local("Inter-Light"),
    url(./assets/Inter/Inter-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"),
    url(./assets/Inter/Inter-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: local("Inter-ExtraBold"),
    url(./assets/Inter/Inter-ExtraBold.ttf) format("truetype");
}
html {
  height: 100%;
}
html,
body {
  // pointer-events: none;
  overflow: hidden;
  position: relative;
  height: 100%;
}

#root {
  height: 100%;
}
body {
  overflow:hidden;
  background: black;
  font-family: "Inter-Regular", Arial, Helvetica, sans-serif;
}
* {
  margin: 0;
  padding: 0;
  // position: relative;
  // -webkit-overflow-scrolling: auto !important;
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch !important;
  // scroll-behavior: smooth;
}
.App {
  height: 100%;
}
.swiper-container {
  width: 100%;
  height: 100%;
  transition: 0.5s ease filter;
}
.swiper-container::-webkit-scrollbar {
  display: none;
}
.swiper-wrapper {
  // transform: none !important;
  // -webkit-backface-visibility: hidden  !important;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  // -webkit-transform: none !important;
  // // -webkit-transform: translateZ(0) !important;
  // -webkit-backface-visibility: hidden  !important;
  // overflow: visible;
  // overflow: visible;
  // overflow-y: scroll;
  overflow-y: auto;
}

.swiper-container-animate {
  // perspective: 1000px;
  animation: swipeAnimation3 2500ms ease-in-out;
  overflow: visible;

  // perspective: 1000px;
  // animation: swipeAnimation 2500ms ease-in-out;
}
// .swiper-container :nth-child(1) {
//   border: 5px solid red;

// }
// .swiper-wrapper {
//   perspective: 1000px;
// }
// .swiper-container-animate > .swiper-wrapper {
//   // border: 5px solid green;
//   overflow:visible;

// }
// .swiper-container-animate .swiper-slide:nth-of-type(1) {
//   animation: swipeAnimation1 2500ms ease-in-out;
//   transform-origin: bottom center;
//   // border: 5px solid red;
// }
// .swiper-container-animate .swiper-slide:nth-of-type(2) {
//   // border: 5px solid blue;
//   animation: swipeAnimation2 2500ms ease-in-out;
//   transform-origin: top center;
// }

@keyframes swipeAnimation1 {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(90deg);
  }
}

@keyframes swipeAnimation2 {
  0% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes swipeAnimation3 {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.7) translateY(30vh);
  }
  0% {
    transform: scale(1);
  }
}

// .swiper-container-animate {
//   .swiper-slide-active {
//     border: 5px solid red;
//   }
//   .swiper-slide-next {
//     border: 5px solid blue;
//   }
//   .swiper-slide-prev {
//     border: 5px solid red;

//   }
// }
.slide-active {
  transition: 1s ease filter;
}
.blur {
  filter: blur(18px);
}
.purple {
  background: #ff0088;
  background: linear-gradient(to top left, #ff0088 0%, #9000ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}
@keyframes swipeAnimation {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.7);
  }
  0% {
    transform: scale(1);
  }
}

h1,h2 {
  font-weight: normal;
}
// * { outline: 1px red solid !important; visibility: visible !important }
