.AboutBody {
  text-align: left;
  color: white;
  max-width: 1000px;
  margin: auto;
  // padding: 20rem 0;
  @media only screen and (max-width: 1370px) {
    margin-left: 10vw;
    margin-right: 10vw;
    h1,
    h2,
    p,
    ul,
    .skills {
      display: block;
      width: 100%;
      box-sizing: border-box;

      max-width: 700px;
      margin-left: 0 !important;
    }
  }

  h1,
  h2,
  p,
  ul {
    margin: 0 7vw;
    font-weight: unset;
  }

  h1 {
    color: #ff0088;
    font-size: 2rem;
    font-family: "Inter-Bold";
  }

  h2 {
    font-size: 1.5rem;
    font-family: "Inter-Regular";
    margin-top: 3vh;
    margin-bottom: 4vh;
  }
  span {
    font-family: "Inter-Bold";
  }

  p {
    line-height: 170%;
    margin-bottom: 2rem;
    font-size: 1.12rem;
    a {
      color: white;
      opacity: 1;
    }
  }
  .skills {
    display: flex;
    margin: 0 7vw;
    margin-bottom: 4rem;
    @media only screen and (max-width: 530px) {
      flex-wrap: wrap;
    }
    & > div {
      width: 100%;
      min-width: 11rem;
      h2 {
        font-family: "Inter-SemiBold";
        margin-left: 0;
      }
      ul {
        margin-right: 0;
        margin-left: 0;
        // display: flex;
        // flex-wrap: wrap;
        li {
          display: inline-block;
          margin-top: 1rem;
          // width: 50%;
          min-width: 9rem;
          // margin-left: 1rem;
          // margin:1rem;
          // width: 10rem;
        }
      }
    }
  }
}
