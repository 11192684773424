.Cube.zoom {
  animation: zoomInOut 2500ms ease-in-out;

}
.Cube {
  transition: 1s ease filter;

  width: 100vw;
  height: 100%;
  position: relative;
  perspective: 1000px;
  overflow: visible !important;

  .wrapper.show-0 {
    transform: translate3d(0px, 0px, -50vh);
  }
  .wrapper.show-1 {
    transform: translate3d(0px, 0px, -50vh) rotateX(90deg);
  }
  .wrapper {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: 2.5s ease transform;
    backface-visibility: hidden;

    .slide-1,
    .slide-2 {
      backface-visibility: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .slide-1 {
      transform: translate3d(0px, 0px, 50vh);
    }
    .slide-2 {
      transform: rotateX(-90deg) translate3d(0px, 0px, 50vh);

    }
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.6) translateY(30vh);
  }
  0% {
    transform: scale(1);
  }
}
