@import "../../Shared/FadeIn.scss";

.ScrollButton {
  opacity: 0;
  animation: 0.3s linear fadeIn 1.8s forwards;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;

  button {
    transition: 0.3s ease opacity;
    opacity: 0.8;
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    outline: none;
    border: 2px solid white;

    background: transparent;
    img {
      filter: invert(1);
      vertical-align: middle;
      width: 40%;
    }
  }
}
