.MenuContent {
  bottom: 0;
  position: fixed;
  margin: 10vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  li {
    list-style: none;
    margin: 0.4rem;
    button {
      background: transparent;
      padding: 0;
      border: none;
      cursor: pointer;
    }
    span {
      color: white;
      font-family: "Inter-Bold";
      font-size: clamp(2rem, 8vw, 3rem);
    }
  }

  .divider {
    margin: 2rem 0;
    background: white;
    height: 2px;
    width: 20%;
    transform: translateX(10px);
  }
  .icons {
    margin: 0 0.6rem;
    .Icon {
      width: clamp(2.2rem, 10vw, 3rem);
      height: clamp(2.2rem, 10vw, 3rem);
    }
    display: flex;
  }
}
