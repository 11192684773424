.Contact {
  padding-top: 7rem;
  .icons {
    margin-top: 5rem;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    .Icon {
      margin: 0 0.6rem;
      background: linear-gradient(to top left, #ff0088 0%, #9000ff 100%);
      width: clamp(2.2rem, 10vw, 3rem);
      height: clamp(2.2rem, 10vw, 3rem);
    }
    display: flex;
  }
  p {
    text-align: center;
    a {
      color: white;
    }
    color: white;
    opacity: 0.5;
    font-size: clamp(0.7rem, 2vw, 0.9rem);
    margin-bottom: 0.4rem;
  }
  margin-bottom: 1rem;
}
