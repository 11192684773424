.Form {
  label {
    margin-bottom: 1rem;
    display: block;
    font-family: "Inter-SemiBold";
  }

  button {
    padding: 1rem 2rem;
    margin: 0 7vw;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    color: white;
    font-family: "Inter-SemiBold";
    font-size: 1.5rem;
    background: linear-gradient(to top left, #ff0088 0%, #9000ff 100%);
    background-size: 100% 100%;
    transition: 0.3s ease transform;
    &:hover {
      transform: translateX(1rem);
    }
    &:disabled {
        cursor: default;
        transform: none ;
    }
    @media only screen and (max-width: 1370px) {
      margin-left: 0;
    }
  }
}
