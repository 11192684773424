.About {
  background: black;
}
::-webkit-scrollbar {
    width:10px;
    background:#1d1d1d;
  }
  ::-webkit-scrollbar-thumb {
    // height:10px;
    background:white;
    // box-shadow:0 -1000vh 0 1000vh white;
  }
