.field:focus ~ .top-left,
.field:focus ~ .bottom-right {
  width: 100%;
  height: calc(100% + 0.2rem);
  padding: 0.1rem;
}

.Input {
  padding-left: 1rem;
  background: #1d1d1d;
  border-radius: 7px;
  margin: 2rem 7vw;
  width: 70%;
  position: relative;
  label {
    position: absolute;
    visibility: hidden;
  }
  textarea {
      padding-top: 1rem;
      width: 90%;
      resize: none;
  }
  .field {
    border-radius: 7px;
    outline: none;
    width: 100%;
    height: 100%;
    background: #1d1d1d;
    border: none;
    color: white;
  }
  .field:-webkit-autofill {
    transition: background-color 9999999999s ease-in-out 0s,
      color 9999999999s ease-in-out 0s;
  }
  .top-left,
  .bottom-right {
    transition: 1s ease height, 1s ease width;
    border-radius: 7px;
    position: absolute;
    width: 0;
    height: 0;
    z-index: -1;
    background: linear-gradient(to top left, #ff0088 0%, #9000ff 100%);
  }

  .top-left {
    // height: calc(100% + 0.2rem);
    bottom: -0.2rem;
    right: -0.2rem;
    // padding: 1rem;
  }
  .bottom-right {
    // height: calc(100% + 0.2rem);
    top: -0.2rem;
    left: -0.2rem;
  }
}
@media only screen and (max-width: 1370px) {
  .Input {
    max-width: 700px;
    margin-left: 0;
    width: 90%;
  }
}
