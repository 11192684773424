#nav-icon4 {
  z-index: 1000;
  height: 4rem;
  width: 60px;
  transform: rotate(0deg) scale(0.7) translateY(-15px);
  transition: 0.5s ease-in-out;
}

#nav-icon4 span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: linear-gradient(to right, #FF0088 0%, #9000FF 50%, white 50%);
  background-position: 100%;
  background-size: 200% 100%;
  opacity: 1;
  left: 0;

  transform: rotate(0deg) translateY(7px);
  transition: 0.25s ease-in-out;
}
#nav-icon4:hover span {
  background-position: 0 100%;
}
#nav-icon4 span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 18px;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 36px;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}
