.Icon {
  margin-right: 1rem;
  background: white;
  transition: 0.3s ease transform; 
  &:hover {
    // background:red;
    transform:scale(1.1);
    background: linear-gradient(to top left, #ff0088 0%, #9000ff 100%);
  }
}
